<!--
 * @Descripttion: 公告详情
 * @Date: 2022-01-20 14:01:29
 * @Author: lingjunbin@imyfone.cn
 * @LastEditTime: 2023-06-09 17:04:38
 * @LastEditors: tangjz
-->
<template>
  <div class="announcement-del">
      <GoBack />
      <div class="announcement-content" id="announceContent">
        <div class="content-header">
          <h2> {{announcementDel.title}} </h2>
          <div class="author-updated">
              <span> {{announcementDel.author}} </span>
              <span> {{formatDate(announcementDel.publish_at ? announcementDel.publish_at : announcementDel.updated_at)}} </span>
          </div>
        </div>
        <div>
          <div class="content editor-content" v-html="announcementDel.content"></div>
          <ul class="file" v-if="announcementDel.file_urls">
            <FileList :fileList="announcementDel.file_urls" ></FileList>
          </ul>
        </div>
        <div class="eye">
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.72 14.62a23.473 23.473 0 0 1-.486-.62 25.191 25.191 0 0 1 2.958-3.264C8.31 8.786 11.072 7 14 7s5.69 1.785 7.808 3.736A25.194 25.194 0 0 1 24.766 14a25.194 25.194 0 0 1-2.958 3.264C19.69 19.214 16.928 21 14 21s-5.69-1.785-7.808-3.736a25.191 25.191 0 0 1-2.472-2.643z" stroke="#999" stroke-width="2"/><circle cx="14" cy="14" r="3" stroke="#999" stroke-width="2"/></svg>
            <span> {{announcementCount.read_count}}人已读，{{announcementCount.unread_count}}人未读 </span>
        </div>
      </div>
  </div>
  <el-image-viewer v-if="imgpreVisible" :url-list="src" :hide-on-click-modal="true" @close="closeImageviewer"></el-image-viewer>
</template>

<script>
import { formatDate } from '@/utils/filterHtml.js'
import { getAnnouncementInfo } from '@/apis/announcement.js'
import { getReadUserList, setJournalRead } from '@/apis/journal.js'
import { reactive, toRefs, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import waterMaskFn from '@/utils/waterMask.js'
import imagePre from '@/mixin/imagePreview.js'
import store from '@/store'

export default {
  beforeRouteLeave (to, from, next) {
    console.log(to, from)
    // 公告page 重置 不是从详情页回来的
    if (!to.path.includes('announcement')) {
      store.commit('announcement/setAnnouncementData', { limit: 10, page: 1, cate_id: 0, type: 1 })
      store.commit('announcement/setClickItem', 0)
      store.commit('announcement/setAnnounceData')
    }
    next()
  },
  setup () {
    const state = reactive({
      announcementId: '', // 文章ID
      announcementDel: {}, // 文章详情
      announcementCount: {
        read_count: 0,
        unread_count: 0
      }, // 文章阅读数
      pdfUrl: '',
      imgpreVisible: computed(() => store.state.imagePreVisible),
      waterName: computed(() => store.state.userInfo.userInfos.name)
    })
    const store = useStore()
    const route = useRoute()
    onMounted(() => {
      state.announcementId = route.params.id
      getAnnouncementInfo({ id: state.announcementId }).then(res => { // 获取详情
        if (res.code === 0) {
          res.data.read_status || store.commit('announcement/setunReadAnt', store.state.announcement.unReadAnt - 1)
          state.announcementDel = res.data
          JournalReadType(res.data.read_status)
        }
      }).finally(() => {
        setTimeout(() => {
          waterMaskFn.set(state.waterName, state.waterName)
        }, 500)
      })
    })

    // 监听点击图片
    const { src } = imagePre()
    const closeImageviewer = () => {
      store.commit('setImagePreVisible', false)
    }

    // 获取已读
    function JournalReadType (value) {
      if (value) {
        getUsersList()
        return false
      }
      setJournalRead({ id: state.announcementId, type: 5 }).then(res => { // 已读
        if (res.code === 0) {
          getUsersList()
        }
      })
    }
    // 获取未读已读人数
    const getUsersList = () => {
      getReadUserList({ id: state.announcementId, type: 5 }).then(res => {
        if (res.code === 0) {
          state.announcementCount.read_count = res.data.read_count
          state.announcementCount.unread_count = res.data.unread_count
        }
      })
    }

    return {
      ...toRefs(state),
      formatDate,
      closeImageviewer,
      src
    }
  }
}
</script>

<style scoped lang="less">
@import "../../../assets/less/ueditorContent.less";
.announcementDel{
    height: 100%;
}
#announceContent {
  position: relative;
  overflow: hidden;
}
.announcement-content{
    padding: 0 70px;
    margin-top: 8px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    box-sizing: border-box;
    border-radius: 6px;
    .content-header {
      position: relative;
    }
    .file-name {
      z-index: 101;
    }
    h2{
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        color: @default-text-color;
        margin-top: 36px;
        text-align: center;
    }
    .author-updated{
        font-weight: normal;
        font-size: 13px;
        line-height: 25px;
        color: #999999;
        margin: 8px 0 24px;
        text-align: center;
        span{
            padding: 0 10px;
        }
    }
    .content{
        word-wrap:break-word;
        word-break:break-all;
        overflow: hidden;
        margin: 0 0 36px;
        padding-bottom: 36px;
        border-bottom: 1px solid #F1F1F1;
        :deep(img){
          max-width: 978px;
        }
        :deep(img):hover {
          cursor: pointer;
        }
        :deep(table) {
          width: 100% !important;
          border-collapse: collapse;
          padding: 0;
        }
        :deep(tr td) {
          border: 1px solid;
        }
        strong {
          font-weight: bold;
        }
    }
    .file{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 24px;
      :deep(.file-list){
        max-width: 316px;
      }
    }
    .eye{
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      font-size: 12px;
      line-height: 16px;
      color: #777777;
      span{
        margin-left: 8px;
      }
    }
}
</style>
