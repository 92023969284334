/*
 * @Description: 添加水印
 * @Author: heqi
 * @Date: 2022-03-04 10:58:28
 * @LastEditTime: 2022-03-21 17:40:42
 * @LastEditors: heqi
 * @FilePath: \mfzj\src\utils\waterMask.js
 */
var watermark = {}

function setWatermark (args) {
  const dom = document.querySelector('.notice-dialog .el-dialog__body #announceContent') || document.getElementById('announceContent')
  // 声明一个怪异一点的变量，确保id的唯一性
  var id = '111.222.333.456'
  var xIndex = 65 // 绘制文本的 x 坐标位置
  var yIndex = 0 // 绘制文本的 y 坐标位置
  // 利用canvas绘制水印信息
  var can = document.createElement('canvas')
  can.width = 320
  can.height = 210
  var cans = can.getContext('2d')
  cans.rotate(30 * Math.PI / 180)
  cans.font = '28px Microsoft YaHei'
  // ziti yanse
  cans.fillStyle = '#C4C4C4'
  // cans.fillStyle = '#000'
  cans.textAlign = 'left'
  cans.textBaseline = 'Middle'
  for (let i = 0; i < args.length; i++) {
    if (i % 2 === 0) {
      yIndex = 15
      xIndex = 260
    } else {
      yIndex = 5
      xIndex = 65
    }
    cans.fillText(args[i], xIndex, yIndex) // 绘制水印文案
  }
  // 创建div用于显示
  var div = document.createElement('div')
  div.id = id
  div.style.pointerEvents = 'none'
  div.style.top = '-50px'
  div.style.left = '-90px'
  div.style.position = 'absolute'
  div.style.zIndex = '10'
  div.style.opacity = '.2'
  div.style.width = dom && dom.clientWidth + 500 + 'px'
  div.style.height = dom && dom.clientHeight + 500 + 'px'
  // div承载水印显示
  div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat'
  dom && dom.appendChild(div)
  return id
}

function createObserver (id, args) {
  const dom = document.querySelector('.notice-dialog .el-dialog__body .notice-content') || document.getElementById('announceContent')
  // 创建一个观察器实例并传入回调函数
  var observer = new MutationObserver(() => {
    if (document.getElementById(id) === null) {
      id = setWatermark(args)
    }
  })
  var option = {
    childList: true, // 子元素的变动
    subtree: true // 所有下属节点（包括子节点和子节点的子节点）的变动
  }
  dom && observer.observe(dom, option) // 观察body下节点的变化
}

watermark.set = function () {
  const dom = document.querySelector('.notice-dialog .el-dialog__body .notice-content') || document.getElementById('announceContent')
  const args = Array.prototype.slice.apply(arguments)
  const id = setWatermark(args)
  // 检测如果水印被去掉了，自动给加上
  createObserver(id)
  // 在窗口大小改变之后,自动触发加水印事件
  dom.onresize = function () {
    setWatermark(args)
  }
}

export default watermark
